.tooltip-content {
  @apply w-60 !important;
}

.salary-table tr > th,
.salary-table tr > td {
  @apply bg-white;
}

.salary-table tr > th:first-child,
.salary-table tr > td:first-child {
  @apply sticky left-0 z-10;
}

.salary-table tr:first-child td {
  @apply bg-gray-200 font-bold !important;
}

.salary-table tr:hover td {
  @apply bg-gray-300 !important;
}
